import axios from "axios";
import { UserManagementPromiseClient } from "@/pb/isotronic/hosted/central/gatekeeper/authorizationapi/rpc/proto/user/UserManagementService_grpc_web_pb.js";
import { CompanyManagementPromiseClient } from "@/pb/isotronic/hosted/central/gatekeeper/authorizationapi/rpc/proto/company/Company_grpc_web_pb.js";
import {
  LoginUserRequest,
  UpdateGeneralRequest,
  UpdatePasswordRequest,
} from "@/pb/isotronic/hosted/central/gatekeeper/authorizationapi/rpc/proto/user/UserManagementService_pb.js";
import { UpdateShiftsRequest } from "@/pb/isotronic/hosted/central/gatekeeper/authorizationapi/rpc/proto/company/Company_pb.js";
import { MachinePromiseClient } from "@/pb/isotronic/hosted/central/gatekeeper/manufacturingapi/rpc/proto/MachineService_grpc_web_pb.js";
import { MachineListRequest } from "@/pb/isotronic/hosted/central/gatekeeper/manufacturingapi/rpc/proto/MachineService_pb.js";
import { grpcApiUrl } from "@/helper";
import google_protobuf_empty_pb from "google-protobuf/google/protobuf/empty_pb";
import options from "@/interceptors";
// https://stackoverflow.com/questions/60491988/how-to-access-vue-instance-in-vuex

const login = ({ commit, dispatch }, authData) => {
  return new Promise((resolve, reject) => {
    let userClient = new UserManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    // let userClient = new UserManagementPromiseClient(grpcApiUrl(), null, null);
    let req = new LoginUserRequest();
    req.setEmail(authData.email);
    req.setPassword(authData.password);
    userClient.loginUser(req, {}).then(
      (response) => {
        let userInfo = response.toObject();
        commit("LOGIN", userInfo);
        dispatch(
          "tracking/sendTracking",
          { event: "login", note: "" },
          { root: true }
        );
        resolve();
      },
      (error) => {
        console.error("login error", error);
        reject(error);
      }
    );
  });
};

//this does not add stuff to the store, but I added it here to
//keep user grpc things in one location
//vue instance is manually sent in because there were issues with page refresh
const verifyUserGRPC = () => {
  return new Promise((resolve, reject) => {
    let userClient = new UserManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    userClient.verifyUser(new google_protobuf_empty_pb.Empty(), {}).then(
      () => {
        resolve();
      },
      (error) => {
        console.error("verify user error", error);
        reject(error);
      }
    );
  });
};

const logout = ({ commit, dispatch }) => {
  return new Promise((resolve) => {
    delete axios.defaults.headers.common["Authorization"];
    commit("LOGOUT");
    dispatch(
      "tracking/sendTracking",
      { event: "logout", note: "normal logout" },
      { root: true }
    );
    resolve();
  });
};

const updateUserGeneral = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    let userClient = new UserManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new UpdateGeneralRequest();
    req.setEmail(data.email);
    req.setFirstName(data.firstName);
    req.setLastName(data.lastName);
    req.setTimeZone(data.timeZone);
    userClient.updateGeneral(req, {}).then(
      () => {
        commit("UPDATE_USER_GENERAL", data);
        resolve();
      },
      (error) => {
        console.error("update user general error", error);
        reject(error);
      }
    );
  });
};

const updatePassword = ({ state }, data) => {
  return new Promise((resolve, reject) => {
    let userClient = new UserManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new UpdatePasswordRequest();
    req.setEmail(state.userInfo.email);
    req.setOldPassword(data.oldPassword);
    req.setNewPassword(data.newPassword);
    userClient.updatePassword(req, {}).then(
      () => {
        resolve();
      },
      (error) => {
        console.error("update user password error", error);
        reject(error);
      }
    );
  });
};

const updateShifts = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    let companyClient = new CompanyManagementPromiseClient(
      grpcApiUrl(),
      null,
      options
    );
    let req = new UpdateShiftsRequest();
    req.setCompanyId(rootState.base.userInfo.companyUuid);
    req.setShifts(JSON.stringify(data.shifts));
    companyClient.updateShifts(req, {}).then(
      () => {
        commit("SET_USER_SHIFTS", data.shifts);
        resolve();
      },
      (error) => {
        console.error("update user shifts", error);
        reject(error);
      }
    );
  });
};

const getMachines = ({ commit, rootState }) => {
  if (rootState.base.machines !== null) {
    return; //only fetch machines if they have not yet been fetched yet
  }
  return new Promise((resolve, reject) => {
    let machineClient = new MachinePromiseClient(grpcApiUrl(), null, options);
    let req = new MachineListRequest();
    req.setCompany(rootState.base.userInfo.companyUuid);
    machineClient.getMachines(req, {}).then(
      (response) => {
        let list = response.toObject().machinesList;
        commit("SET_MACHINES", list);
        resolve();
      },
      (error) => {
        console.error("fetching machines", error);
        reject(error);
      }
    );
  });
};

export default {
  login,
  verifyUserGRPC,
  logout,
  updateUserGeneral,
  updatePassword,
  getMachines,
  updateShifts,
};
